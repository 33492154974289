<template>
  <div class="bmi-box">
    <div v-if="loading">
      <div class="text-center">
        <b-spinner variant="primary" />
      </div>
    </div>
    <div v-else>
      <b-alert v-if="error" variant="danger" class="mt-3" dismissible :show="Boolean(error)">{{ error }}</b-alert>
      <template v-else>
        <img src="@/assets/images/bmi.png" :alt="$t('user.bmi')" class="img-fluid mb-3" />
        <div class="bmi-box-info">
          <p>
            <b>{{ $t('user.bmi') }}</b>
            {{ client.bmi || '---' }}
          </p>
          <p>
            <b>{{ $t('user.classification') }}</b>
            {{ client.bmi_detail.classification || '---' }}
          </p>
          <p>
            <b>{{ $t('user.fat-percentage') }}</b>
            {{ client.fat_perc || '---' }}
          </p>
        </div>
        <b-table-simple hover small caption-top responsive class="bmi-box-table">
          <caption>{{ $t('user.probability-of-developing') }}</caption>
          <b-tbody>
            <b-tr :style="`background-color: #${client.bmi_detail.cardiovascular_color}`">
              <b-td>{{ $t('user.cardiovascular') }}</b-td>
              <b-td>{{ client.bmi_detail.cardiovascular || '---' }}</b-td>
            </b-tr>
            <b-tr :style="`background-color: #${client.bmi_detail.bronchopulmonary_color}`">
              <b-td>{{ $t('user.bronchopulmonary') }}</b-td>
              <b-td>{{ client.bmi_detail.bronchopulmonary || '---' }}</b-td>
            </b-tr>
            <b-tr :style="`background-color: #${client.bmi_detail.endocrine_color}`">
              <b-td>{{ $t('user.endocrine') }}</b-td>
              <b-td>{{ client.bmi_detail.endocrine || '---' }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>
    </div>
    <span class="material-symbols-rounded common-modal-close" @click="$emit('onClose')">
      close
    </span>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'

export default {
  props: {
    info: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      loading: false,
      error: "",
      client: null,
    };
  },
  created() {
    if (this.info) {
      this.client = this.info
    } else {
      this.loadClientData()
    }
  },
  computed: {

  },
  methods: {
    ...mapActions(useGlobalStore, ['getClient']),
    async loadClientData() {
      // console.log('loadClientData...')
      this.loading = true;
      this.error = "";

      const req = await this.getClient(this.$route.params.id);

      if (req?.data?.success) {
        this.client = req.data.client || null
      } else {
        // console.log('loadClientData, req: ', req)
        this.error = this.$t('msg.error')

        if (req.message && req.response?.data?.error) {
          this.error = req.message + ': ' + this.$t('msg.' + req.response.data.error)
        }
      }

      this.loading = false;
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.bmi-box {
  .common-modal-close {
    top: 10px;
    right: 10px;
  }

  &-info {
    p {
      font-size: 16px;
    }
  }

  &-table {
    margin: 0;
  }
}
</style>