<template>
  <Layout>

    <div v-if="client" class="text-center text-md-left page-headline mb-4">
      <h1 class="display-3">{{ client?.fio }}</h1>
    </div>

    <div v-if="loading">
      <div class="text-center">
        <b-spinner variant="primary" />
      </div>
    </div>
    <div v-else>
      <b-alert v-if="error" variant="danger" class="mt-3" dismissible :show="Boolean(error)">{{ error }}</b-alert>
      <template v-else>

        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-xl-6">

                <!-- Client info -->
                <div class="client-info">
                  <div class="form-group mb-2">
                    <label>{{ $t('user.dob') }}</label>
                    <div class="input-group">
                      {{ client.birthday || '---' }}
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <label>{{ $t('user.gender') }}</label>
                    <div class="input-group">
                      {{ $t(`user.gender-${client.gender}`) }}
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <label>{{ $t('user.login') }}</label>
                    <div class="input-group">
                      {{ client.email }}
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <label>{{ $t('user.height') }}</label>
                    <div class="input-group">
                      <template v-if="client.height">{{ client.height }} {{ $t('unit.cm') }}</template>
                      <template v-else>---</template>
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <label>{{ $t('user.weight') }}</label>
                    <div class="input-group">
                      <template v-if="client.weight">{{ client.weight }} {{ $t('unit.kg') }}</template>
                      <template v-else>---</template>
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <label>{{ $t('user.bmi') }}<span v-if="client?.bmi_detail" class="material-symbols-rounded bmi-icon"
                        @click="showModal = true">help</span></label>
                    <div class="input-group">
                      {{ client.bmi || '---' }}
                    </div>
                  </div>

                </div>

              </div>
              <div class="col-xl-6">

                <UserDiagrams />

              </div>
            </div>

            <Debuginfo>
              <div>
                <pre>client: {{ client }}</pre>
              </div>
            </Debuginfo>

          </div>
        </div>
      </template>
    </div>

    <b-modal v-if="client?.bmi_detail" v-model="showModal" size="md" hide-header hide-footer centered>
      <UserBmi :info="(cacheLang === lang) ? client : null" @onClose="showModal = false" />
    </b-modal>

  </Layout>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import Layout from "@/views/layouts/main";
import UserDiagrams from "@/components/user-diagrams";
import UserBmi from "@/components/user-bmi";

export default {
  components: {
    Layout,
    UserDiagrams,
    UserBmi,
  },
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  data() {
    return {
      loading: false,
      error: "",
      client: null,
      showModal: false,
      cacheLang: "",
    };
  },
  created() {
    this.loadClientData();
  },
  computed: {
    ...mapState(useGlobalStore, ['userRoles', 'lang']),
    role() {
      if (this.userRoles?.includes("a")) {
        return 'admin'
      }

      if (this.userRoles?.includes("c")) {
        return 'company'
      }

      return
    },
    pageTitle() {
      return `${this.$t('app.title')} | ${this.$t(`page.${this.role}.user`)}: ${this.client?.fio}`
    },

  },
  methods: {
    ...mapActions(useGlobalStore, ['getClient']),
    // async loadData() {
    //   try {
    //     this.loading = true;
    //     this.error = "";

    //     await this.loadClientData();
    //   } catch (error) {
    //     console.log("loadData Error: ", error);
    //     this.error = error
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    async loadClientData() {
      // console.log('loadClientData...')
      this.loading = true;
      this.error = "";

      const req = await this.getClient(this.$route.params.id);

      if (req?.data?.success) {
        this.client = req.data.client || null
        this.cacheLang = this.lang
      } else {
        // console.log('loadClientData, req: ', req)
        this.error = this.$t('msg.error')

        if (req.message && req.response?.data?.error) {
          this.error = req.message + ': ' + this.$t('msg.' + req.response.data.error)
        }
      }

      this.loading = false;
    },
  },
  watch: {

  },
};
</script>
<style scoped lang="scss">
.client-info {
  .input-group {
    font-size: 16px;
  }

  .bmi-icon {
    cursor: pointer;
    font-size: 18px;
    vertical-align: top;
    position: absolute;
    margin: -8px 0 0 4px;
    transition: opacity 0.15s ease-in-out;
  }

  .bmi-icon:hover {
    opacity: .8;
  }
}
</style>
