<template>
  <div class="user-diagrams">
    <!-- Date range -->
    <div class="user-diagrams-range form-group mb-2">
      <label>{{ $t('form.date-range') }}</label>
      <div class="input-group input-group-merge">
        <date-picker range v-model="range" :disabled-date="(date) => date >= new Date()" :lang="datepickerConfig"
          value-type="YYYY-MM-DD" format="YYYY-MM-DD" :placeholder="$t('form.date-range-placeholder')" :disabled="loading"
          input-class="form-control" />
      </div>
    </div>

    <!-- Diagrams -->
    <div class="user-diagrams-box">
      <div v-if="loading">
        <div class="text-center">
          <b-spinner variant="primary" />
        </div>
      </div>
      <div v-else>
        <b-alert v-if="error" variant="danger" class="mt-3" dismissible :show="Boolean(error)">{{
          error
        }}</b-alert>
        <template v-else>

          <div v-if="diagrams?.length">
            <div>
              <h3>{{ $t('diagram.steps') }}</h3>
              <apexchart height="380" type="line" class="apex-charts" :series="seriesSteps"
                :options="chartOptionsSteps" />
            </div>

            <div>
              <h3>{{ $t('diagram.calories-burned') }}</h3>
              <apexchart height="380" type="line" class="apex-charts" :series="seriesCalories"
                :options="chartOptionsCalories" />
            </div>

            <div>
              <h3>{{ $t('diagram.pulse') }}</h3>
              <apexchart height="380" type="line" class="apex-charts" :series="seriesPulse"
                :options="chartOptionsPulse" />
            </div>
          </div>
          <div v-else class="text-center">
            <template v-if="range && range[0] && range[1]">{{ $t('table.no-data') }}</template>
          </div>

          <Debuginfo>
            <pre>diagrams: {{ diagrams }}</pre>
            <!-- <pre>seriesSteps: {{ seriesSteps }}</pre> -->
            <!-- <pre>chartOptionsSteps: {{ chartOptionsSteps }}</pre> -->
            <!-- <pre>seriesCalories: {{ seriesCalories }}</pre> -->
            <!-- <pre>chartOptionsSteps: {{ chartOptionsSteps }}</pre> -->
          </Debuginfo>

        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import DatePicker from "vue2-datepicker";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      loading: false,
      error: "",
      range: [],
      datepickerConfig: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },

      diagrams: [],
      options: {
        chart: {
          stacked: false,
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [0, 2]
        },
        colors: ['#4fc6e1', '#ebf2f6', '#f1556c', '#4fc6e1'],
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        tooltip: {
          followCursor: true,
          y: {
            formatter(y) {
              return y;
            }
          }
        },
        grid: {
          borderColor: '#f1f3fa'
        },
        legend: {
          offsetY: -10,
        },
        responsive: [{
          breakpoint: 600,
          options: {
            yaxis: {
              show: false
            },
            legend: {
              show: false
            }
          }
        }]
      },
    }
  },
  created() {
    if (this.$route.query?.range?.length && this.$route.query.range[0] && this.$route.query.range[1]) {
      this.range = [this.$route.query.range[0], this.$route.query.range[1]]
    } else {
      this.defaultRangeWeek()
    }
  },
  computed: {
    chartOptionsSteps() {
      let options = {
        ...this.options,
        colors: ['#01a2d9', '#ff6895'],
        xaxis: {
          categories: [],
        },
      }

      // upd via this.diagrams
      this.diagrams.map(d => {
        options.xaxis.categories.push(String(d.day))
      })

      return options
    },
    seriesSteps() {
      let series = [
        {
          name: this.$t('diagram.steps'),
          type: 'bar',
          data: []
        },
        {
          name: this.$t('diagram.steps-mid-value'),
          type: 'line',
          data: []
        }
      ]

      // upd via this.diagrams
      let mid = 0
      this.diagrams.map(d => {
        series[0].data.push(d.steps)
        mid += d.steps
      })

      mid = mid / this.diagrams.length
      this.diagrams.map(d => {
        series[1].data.push(Number(mid).toFixed())
      })

      return series
    },
    chartOptionsCalories() {
      let options = {
        ...this.options,
        colors: ['#f3be22', '#ff6895'],
        xaxis: {
          categories: [],
        },
      }

      // upd via this.diagrams
      this.diagrams.map(d => {
        options.xaxis.categories.push(String(d.day))
      })

      return options
    },
    seriesCalories() {
      let series = [
        {
          name: this.$t('diagram.calories-burned'),
          type: 'bar',
          data: []
        },
        {
          name: this.$t('diagram.calories-burned-mid-value'),
          type: 'line',
          data: []
        }
      ]

      // upd via this.diagrams
      let mid = 0
      this.diagrams.map(d => {
        series[0].data.push(d.calories)
        mid += d.calories
      })

      mid = mid / this.diagrams.length
      this.diagrams.map(d => {
        series[1].data.push(Number(mid).toFixed())
      })

      return series
    },
    chartOptionsPulse() {
      let options = {
        ...this.options,
        stroke: {
          width: [0, 0, 0, 2]
        },
        colors: ['#fecedc', '#ffa2be', '#ff6895', '#4fc6e1'], // min, mid, max, middle val
        xaxis: {
          categories: [],
        },
      }

      // upd via this.diagrams
      this.diagrams.map(d => {
        options.xaxis.categories.push(String(d.day))
      })

      return options
    },
    seriesPulse() {
      let series = [
        {
          name: this.$t('diagram.pulse_min'),
          type: 'bar',
          data: []
        },
        {
          name: this.$t('diagram.pulse_mid'),
          type: 'bar',
          data: []
        },
        {
          name: this.$t('diagram.pulse_max'),
          type: 'bar',
          data: []
        },
        {
          name: this.$t('diagram.pulse-mid-value'),
          type: 'line',
          data: []
        }
      ]

      // upd via this.diagrams
      let mid = 0
      this.diagrams.map(d => {
        series[0].data.push(d.pulse_min)
        series[1].data.push(d.pulse_mid)
        series[2].data.push(d.pulse_max)
        mid += d.pulse_mid
      })

      mid = mid / this.diagrams.length
      this.diagrams.map(d => {
        series[3].data.push(Number(mid).toFixed())
      })

      return series
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ['getWorkout']),
    async loadRangeDiagram() {
      // console.log('loadRangeDiagram...')

      this.loading = true;
      this.error = "";

      // post, admin, company: "day_from": "2023-10-10",  "day_to": "2023-10-17", "client_id": 25
      const params = {
        client_id: this.$route.params.id,
        day_from: this.range[0],
        day_to: this.range[1],
      }

      const req = await this.getWorkout(params);

      if (req?.data?.success) {
        this.diagrams = req.data.list || []
      } else {
        // console.log('loadClientData, req: ', req)
        this.error = this.$t('msg.error')

        if (req.message && req.response?.data?.error) {
          this.error = req.message + ': ' + this.$t('msg.' + req.response.data.error)
        }
      }

      this.loading = false;
    },
    defaultRangeWeek() {
      let date_today = new Date();

      let first_day_of_the_week = new Date(date_today.setDate(date_today.getDate() - date_today.getDay() + 1));
      let last_day_of_the_week = new Date(date_today.setDate(date_today.getDate() - date_today.getDay() + 7));
      // console.log(`The first date of the week is: ${this.$helpers.formatDate_YYYY_MM_DD(first_day_of_the_week)}`);
      // console.log(`The last day of the week is: ${this.$helpers.formatDate_YYYY_MM_DD(last_day_of_the_week)}`);

      // this.range = ["2023-09-25", "2023-10-01"]
      this.range = [this.$helpers.formatDate_YYYY_MM_DD(first_day_of_the_week), this.$helpers.formatDate_YYYY_MM_DD(last_day_of_the_week)]
    },
  },
  watch: {
    range() {
      if (this.range.length && this.range[0] && this.range[1]) {
        this.loadRangeDiagram()
      } else {
        this.diagrams = []
      }
    },
  },
};
</script>
<style scoped lang="scss">
.user-diagrams {
  &-box {
    h3 {
      font-size: 20px;
    }
  }
}
</style>
